import React from "react";
import "./BannerImgServ.css";

function BannerImgServ() {
  return (
    <div>
      <div className="bannerimgserv"></div>
    </div>
  );
}

export default BannerImgServ;
