import React from "react";
import "./BannerImg.css";

function BannerImg() {
  return (
    <div>
      <div className="bannerimg"></div>
    </div>
  );
}

export default BannerImg;
