import React from "react";

function Title(props) {
  return (
    <div className="title_info">
      <h6 className="arr__common_title">{props.title}</h6>
    </div>
  );
}

export default Title;
